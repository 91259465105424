.color-picker-container {
    position: relative;
}

.color-picker-container .react-colorful__saturation, .color-picker-container .react-colorful__hue {
    border-radius: 0px;
}

.color-picker-swatch {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    border: 1px solid #212529;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.color-picker-popover {
    background-color: #ffffff;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border: 1px solid #212529;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 99;
}
